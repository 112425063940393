import { UserCircleIcon } from '@heroicons/react/24/solid'
import { Avatar } from '@mui/material'

type UserAvatarProps = {
  src: string
  alt?: string
  height?: number
  className?: string
}

const UserAvatar = ({ src, alt, height, className }: UserAvatarProps) => {
  return src ? (
    <Avatar
      alt={alt}
      src={src}
      className={
        'w-' + (height ?? 12) + ' h-' + (height ?? 12) + ' ' + className
      }
      slotProps={{
        img: {
          loading: 'lazy',
        },
      }}
    />
  ) : (
    <UserCircleIcon
      className={
        'w- rounded-full text-gray-400 ' +
        (height ?? 12) +
        ' h-' +
        (height ?? 12) +
        ' ' +
        className
      }
    />
  )
}

export default UserAvatar
