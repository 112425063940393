import React, { BaseSyntheticEvent, FC } from 'react'

import { PlayCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Badge, BadgeProps, Card, CardHeader, styled } from '@mui/material'
import { NOTIFICATION_RESOURCE_TYPE } from 'api/src/common/enums'
import { isNil } from 'ramda'

import useAnalytics from 'src/lib/hooks/useAnalytics'

import IconButton from '../Library/IconButton'

import { NotificationItemUI } from './types'

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    left: -6,
    top: 6,
  },
}))

interface NotificationItemProps {
  notification: NotificationItemUI
  onClick?: (event: BaseSyntheticEvent) => void
  onDismiss: (event: BaseSyntheticEvent) => void
}

const resourceLabel: Record<NOTIFICATION_RESOURCE_TYPE, string> = {
  [NOTIFICATION_RESOURCE_TYPE.AUTOMATION]: 'Automation',
  [NOTIFICATION_RESOURCE_TYPE.BASEROW]: 'Workflows',
  [NOTIFICATION_RESOURCE_TYPE.GOAL]: 'Goal',
  [NOTIFICATION_RESOURCE_TYPE.MEMBERSHIP_GROUP]: 'Group',
  [NOTIFICATION_RESOURCE_TYPE.MEMBERSHIP]: 'Membership',
  [NOTIFICATION_RESOURCE_TYPE.MILESTONE]: 'Milestone',
  [NOTIFICATION_RESOURCE_TYPE.QUEUER_JOB]: 'Queuer Job',
  [NOTIFICATION_RESOURCE_TYPE.SENTIMENT_ANALYSIS]: 'Sentiment Analysis',
  [NOTIFICATION_RESOURCE_TYPE.USER]: 'User',
}

const NotificationItem: FC<NotificationItemProps> = ({
  onClick,
  onDismiss,
  notification,
}) => {
  const { id, title, description, resourceType, createdAt, readDate } =
    notification

  const { trackEvent } = useAnalytics()

  // faker spits out goals like 'calculate cross-platform driver'
  // we change "-" to "_" so we can use the data-testid to extract out the title in testing
  const dataTestId = `notification-card-${title
    ?.trim()
    .replace('-', '_')
    .replace(/ /g, '-')}`

  return (
    <Card
      id={`notification-card-${id}`}
      data-testid={dataTestId}
      elevation={0}
      square
      className={(() => {
        const hoverClass =
          !isNil(onClick) && 'hover:cursor-pointer hover:bg-indigo-100/50'

        return `border-b-[1px] p-0 last-of-type:border-0 ${hoverClass} transition duration-300 ease-in-out`
      })()}
    >
      <CardHeader
        sx={{
          '& .MuiCardHeader-action': {
            alignSelf: 'center',
          },
        }}
        className="flex flex-row items-center justify-center gap-3 px-3 py-3"
        onClick={(event) => {
          if (event.defaultPrevented) {
            return
          }
          event.preventDefault()
          onClick?.(event)
          trackEvent('Notifications', 'click notification', {
            notificationTitle: title,
            notificationResourceType: resourceType,
          })
        }}
        title={
          <div className="flex items-center gap-3">
            <span className={'rounded bg-indigo-500 p-1'}>
              <PlayCircleIcon className="h-4 w-4 shrink-0 text-white" />
            </span>
            <p className="flex flex-row gap-4 text-xs uppercase text-indigo-600">
              {resourceLabel[resourceType]}
              {!readDate && (
                <StyledBadge
                  color="error"
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                />
              )}
            </p>
          </div>
        }
        // Split description on \n to allow multi line descriptions
        subheader={
          <div className="pl-9">
            <p className="line-clamp-1 w-full break-words text-sm text-gray-600">
              {title}
            </p>
            <div className="line-clamp-6 w-full max-w-[230px] break-words text-xs font-normal text-gray-400">
              {description.split('\n').map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
            </div>
          </div>
        }
        action={
          <div className="flex items-center gap-2 px-2 text-xs font-light text-gray-400">
            {/*
             * TODO: 16-MAY: Sam has a better layout option for this, fix coming next week
             * Plan is to have Date and Time on the same line/horizon as the Title (not subheader)
             */}
            <div className="flex-col items-center">
              <div>{createdAt.format('DD MMM YY')}</div>
              <div>{createdAt.format('h:mm A')}</div>
            </div>
            <IconButton
              onClick={(event) => {
                if (event.defaultPrevented) {
                  return
                }
                event.preventDefault()
                onDismiss(event)
                trackEvent('Notifications', 'dismiss notification', {
                  notificationTitle: title,
                  notificationResourceType: resourceType,
                })
              }}
            >
              <XMarkIcon
                title="Dismiss"
                className="h-5 w-5 shrink-0 text-gray-400"
              />
            </IconButton>
          </div>
        }
      />
    </Card>
  )
}

export default NotificationItem
