import { useCallback, useState } from 'react'

import { useLazyQuery, type ApolloError } from '@apollo/client'
import { captureEvent } from '@sentry/browser'
import { ERROR_MESSAGES } from 'api/src/common/constants'
import {
  DismissNotification,
  DismissNotificationVariables,
  GetNotifications,
  GetNotificationsVariables,
  GetUnreadNotificationCount,
  GetUnreadNotificationCountVariables,
  UpdateNotification,
  UpdateNotificationVariables,
  UpdateNotifications,
  UpdateNotificationsVariables,
  ClearAllNotificationsForMember,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

export const NOTIFICATION_FIELDS = gql`
  fragment NotificationFields on Notification {
    id
    title
    createdAt
    resourceType
    readDate
    resourceRouteType
    resourceId
    description
    metadata
  }
`

const GET_UNREAD_NOTIFICATION_COUNT = gql`
  query GetUnreadNotificationCount {
    notificationUnreadCountForMember
  }
`

const GET_NOTIFICATIONS = gql`
  ${NOTIFICATION_FIELDS}
  query GetNotifications {
    notificationsForMember {
      ...NotificationFields
    }
  }
`

const DISMISS_NOTIFICATION = gql`
  mutation DismissNotification($id: Int!) {
    deleteNotification(id: $id) {
      id
    }
  }
`

const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification($id: Int!, $readDate: DateTime!) {
    updateNotification(id: $id, input: { readDate: $readDate }) {
      id
    }
  }
`

const UPDATE_NOTIFICATIONS = gql`
  mutation UpdateNotifications($readDate: DateTime!) {
    updateNotifications(input: { readDate: $readDate }) {
      id
    }
  }
`

const CLEAR_ALL_NOTIFICATIONS = gql`
  mutation ClearAllNotificationsForMember {
    clearAllNotificationsForMember
  }
`

const handleGQLError = (error: ApolloError) => {
  const supportModeError = error?.message?.includes(
    ERROR_MESSAGES.NOT_ALLOWED_IN_SUPPORT_MODE,
  )
  const failedToFetchError = error?.networkError?.message === 'Failed to fetch'

  if (!supportModeError) {
    captureEvent({
      message: 'Error fetching unread notification count',
      level: 'error',
      extra: { error, failedToFetchError },
    })
  }
}

const useNotification = () => {
  const [mutationsInProgress, setMutationsInProgress] = useState<string[]>([])

  const popMutationInProgress = useCallback((mutationName: string) => {
    setMutationsInProgress((prev) => {
      const index = prev.indexOf(mutationName)
      if (index === -1) {
        return prev
      }

      return [...prev.slice(0, index), ...prev.slice(index + 1)]
    })
  }, [])

  const pushMutationInProgress = useCallback((mutationName: string) => {
    setMutationsInProgress((prev) => [...prev, mutationName])
  }, [])

  const mutationIntercept = useCallback(
    <T extends ReturnType<typeof useMutation>[0]>(
      mutationFn: T,
      mutationName: string,
    ) => {
      return (...options: Parameters<T>) => {
        pushMutationInProgress(mutationName)
        return mutationFn(...options)
      }
    },
    [pushMutationInProgress],
  )

  const [getUnreadNotificationCount, getUnreadNotificationCountResult] =
    useLazyQuery<
      GetUnreadNotificationCount,
      GetUnreadNotificationCountVariables
    >(GET_UNREAD_NOTIFICATION_COUNT, {
      onError: (error) => {
        handleGQLError(error)
      },
    })

  const [getNotifications, getNotificationsResult] = useLazyQuery<
    GetNotifications,
    GetNotificationsVariables
  >(GET_NOTIFICATIONS, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      handleGQLError(error)
    },
  })

  const [updateNotification, updateNotificationResult] = useMutation<
    UpdateNotification,
    UpdateNotificationVariables
  >(UPDATE_NOTIFICATION, {
    onCompleted: () => {
      popMutationInProgress('updateNotification')
    },
    onError: (error) => {
      handleGQLError(error)
    },
    refetchQueries: [{ query: GET_NOTIFICATIONS, fetchPolicy: 'network-only' }],
    awaitRefetchQueries: true,
  })

  const [updateNotifications, updateNotificationsResult] = useMutation<
    UpdateNotifications,
    UpdateNotificationsVariables
  >(UPDATE_NOTIFICATIONS, {
    onCompleted: () => {
      popMutationInProgress('updateNotifications')
    },
    onError: (error) => {
      handleGQLError(error)
    },
    refetchQueries: [{ query: GET_NOTIFICATIONS, fetchPolicy: 'network-only' }],
    awaitRefetchQueries: true,
  })

  const [dismissNotification, dismissNotificationResult] = useMutation<
    DismissNotification,
    DismissNotificationVariables
  >(DISMISS_NOTIFICATION, {
    onCompleted: () => {
      popMutationInProgress('dismissNotification')
    },
    onError: (error) => {
      handleGQLError(error)
    },
    refetchQueries: [{ query: GET_NOTIFICATIONS, fetchPolicy: 'network-only' }],
    awaitRefetchQueries: true,
  })

  const [clearAllNotifications] = useMutation<ClearAllNotificationsForMember>(
    CLEAR_ALL_NOTIFICATIONS,
    {
      onCompleted: () => popMutationInProgress('clearAllNotifications'),
      onError: (error) => {
        handleGQLError(error)
      },
      refetchQueries: [
        { query: GET_NOTIFICATIONS, fetchPolicy: 'network-only' },
      ],
      awaitRefetchQueries: true,
    },
  )

  return {
    mutationsInProgress,
    getUnreadNotificationCount,
    getUnreadNotificationCountResult,
    getNotifications,
    getNotificationsResult,
    dismissNotification: mutationIntercept(
      dismissNotification,
      'dismissNotification',
    ),
    dismissNotificationResult,
    updateNotification: mutationIntercept(
      updateNotification,
      'updateNotification',
    ),
    updateNotificationResult,
    updateNotifications: mutationIntercept(
      updateNotifications,
      'updateNotifications',
    ),
    updateNotificationsResult,
    clearAllNotifications,
  }
}

export default useNotification
